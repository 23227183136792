import React from 'react'
import '../../App.css'
import ProjectCards from '../ProjectCards'

function Projects() {
    return (
        <>
            <ProjectCards />
        </>
    )
}

export default Projects;
